import moment from "moment";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { haryanaCities, industryList, itiNameList, tradeList, haryanaDistricts } from "src/assets/data/inputselect";
import CurrentLocation from "src/components/currentLocation";
import Button from "src/components/custom/Button";
import Modal from "src/components/modal";
import { getAuthHeaders, getAuthHeadersFormdata, postMethodApiCall } from "src/services/api";

const TraineeDetails = ({ data }) => {
    const [openModal, setOpenModal] = useState(false)
    const [load, setLoad] = useState(false)
    const [details, setDetails] = useState(data || JSON.parse(localStorage.getItem('loginedUser')))

    const navigate = useNavigate()

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        defaultValues: {
            ...details,
            date_of_birth: moment(data?.date_of_birth).format('YYYY-MM-DD')
        },
    });

    const reportDetails = async () => {
        setLoad(true)
        let res = await postMethodApiCall('/v1/api/trainee/reportInfo', getAuthHeaders(), details)
        if (res?.status === 200 && res?.data?.success) {
            await toast.success('Your information has been reported successfully')
        }
        setLoad(false)
        
    }

    const recordTraineeAttendance = async () => {
        const formData = new FormData();
        if (details?.image_url) {
            formData.append("image_url", details?.image_url[0]); // Append the image file
            formData.append("registration_id",  details?.registration_id)
            formData.append("name",  details?.name)
            formData.append("name_of_your_ITI",  details?.name_of_your_ITI)
            formData.append("name_of_trade",  details?.name_of_trade)
            formData.append("batch_of_trade",  details?.batch_of_trade)
            formData.append("name_of_industry_partner",  details?.name_of_industry_partner)
            formData.append("latitude",  details?.latitude)
            formData.append("longitude",  details?.longitude)
        }

        let res = await postMethodApiCall('/v1/api/trainee/attendance/post', getAuthHeadersFormdata(), formData)
        if (res?.status === 201 && res?.data?.success) {
            await toast.success('Attendance Marked Successfully')
            navigate('/attendance-history')
            reset()
        }
    }

    const onSubmit = (data) => {
        if ("geolocation" in navigator) {
            setOpenModal(true);
            navigator.geolocation.getCurrentPosition(function (position) {
                setDetails({
                    ...data, latitude: String(position.coords.latitude),
                    longitude: String(position.coords.longitude),
                })
            });
        } else {
            console.log("Geolocation is not available in your browser.");
        }
    };

    return (
        <div className="w-full">
            <div className="relative h-full flex justify-center items-center py-3 container mx-auto">
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="w-full px-8 py-6 mt-4 text-left bg-white rounded-md shadow-sm"
                >
                    <div className="flex items-center justify-between gap-2">
                        <p className="m-0 text-[16px] font-semibold text-primary">
                            Trainee Details
                        </p>

                        <div className="space-x-2 flex flex-col items-center justify-center md:flex-row">
                            <Link className="text-sm text-primary" to='/attendance-history'>
                                Attendance history
                            </Link>
                            <Button
                                disabled={load}
                                onClick={reportDetails}
                                title={load ? 'Processing...' : 'Report'}
                                type="button"
                            />
                        </div>

                    </div>
                    <div className="grid grid-cols-1 gap-4 mt-4 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-1">
                        <div className="w-full flex flex-col gap-2">
                            <label className="font-[400] text-xs text-gray-600">Enter your registration number <span className="text-red-400">*</span></label>
                            <input
                                disabled
                                {...register("registration_id", { required: "Registration Id is required" })}
                                placeholder="Registration Id"
                                className="border rounded-md px-3 py-2 text-sm w-full outline-none disabled:bg-gray-50 "
                            />
                            {errors.registration_id && (
                                <span className="text-red-500 text-xs">{errors.registration_id.message}</span>
                            )}
                        </div>

                        <div className="w-full flex flex-col gap-2">
                            <label className="font-[400] text-xs text-gray-600">Full Name <span className="text-red-400">*</span></label>
                            <input
                                disabled
                                {...register("name", { required: "Full name is required" })}
                                placeholder="Full Name"
                                className="border rounded-md px-3 py-2 text-sm w-full outline-none disabled:bg-gray-50 "
                            />
                            {errors.name && <span className="text-red-500 text-xs">{errors.name.message}</span>}
                        </div>


                        <div className="w-full flex flex-col gap-2">
                            <label className="font-[400] text-xs text-gray-600">Phone Number<span className="text-red-400">*</span></label>
                            <input
                                disabled
                                {...register("phone_number", {
                                    required: "Phone number is required",
                                    pattern: {
                                        value: /^[0-9]{10}$/,
                                        message: "Phone number must be 10 digits",
                                    },
                                })}
                                placeholder="Phone Number"
                                className="border rounded-md px-3 py-2 text-sm w-full outline-none disabled:bg-gray-50 "
                                type="tel"
                            />
                            {errors.phone_number && <span className="text-red-500 text-xs">{errors.phone_number.message}</span>}
                        </div>


                        <div className="w-full flex flex-col gap-2">
                            <label className="font-[400] text-xs text-gray-600">Select Gender <span className="text-red-400">*</span></label>
                            <select
                                disabled
                                {...register("gender", { required: "Gender is required" })}
                                className="border rounded-md px-3 py-2 text-sm w-full outline-none disabled:bg-gray-50 "
                            >
                                <option value="">Select Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Others">Others</option>
                            </select>
                            {errors.gender && <span className="text-red-500 text-xs">{errors.gender.message}</span>}
                        </div>


                        <div className="w-full flex flex-col gap-2">
                            <label className="font-[400] text-xs text-gray-600">Date of Birth <span className="text-red-400">*</span></label>
                            <input
                                disabled
                                {...register("date_of_birth", { required: "Date of birth is required" })}
                                className="border rounded-md px-3 py-2 text-sm w-full outline-none disabled:bg-gray-50 "
                                type="date"
                            />
                            {errors.date_of_birth && <span className="text-red-500 text-xs">{errors.date_of_birth.message}</span>}
                        </div>


                        <div className="w-full flex flex-col gap-2">
                            <label className="font-[400] text-xs text-gray-600">Father's Name <span className="text-red-400">*</span></label>
                            <input
                                disabled
                                {...register("father_name", { required: "Father's name is required" })}
                                placeholder="Father's Name"
                                className="border rounded-md px-3 py-2 text-sm w-full outline-none disabled:bg-gray-50 "
                            />
                            {errors.father_name && <span className="text-red-500 text-xs">{errors.father_name.message}</span>}
                        </div>


                        <div className="w-full flex flex-col gap-2">
                            <label className="font-[400] text-xs text-gray-600">Mother's Name <span className="text-red-400">*</span></label>
                            <input
                                disabled
                                {...register("mother_name", { required: "Mother's name is required" })}
                                placeholder="Mother's Name"
                                className="border rounded-md px-3 py-2 text-sm w-full outline-none disabled:bg-gray-50 "
                            />
                            {errors.mother_name && <span className="text-red-500 text-xs">{errors.mother_name.message}</span>}
                        </div>

                        <div className="w-full flex flex-col gap-2">
                            <label className="font-[400] text-xs text-gray-600">Course Affiliation</label>
                            <div className="flex mt-1.5 text-gray-500 gap-2 custom-radio">
                                <label className="flex items-center gap-1">
                                    <input
                                        checked={details?.course_affiliation_ === 'NCVT'}
                                        // disabled
                                        readOnly
                                        type="radio"
                                        value="NCVT"
                                    // {...register("course_affiliation_", { required: "Please select a course affiliation" })}
                                    />
                                    NCVT
                                </label>
                                <label className="flex items-center gap-1">
                                    <input
                                        readOnly
                                        checked={details?.course_affiliation_ === 'SCVT'}
                                        type="radio"
                                        value="SCVT"
                                    // {...register("course_affiliation_", { required: "Please select a course affiliation" })}
                                    />
                                    SCVT
                                </label>
                            </div>
                            {errors.course_affiliation_ && <span className="text-red-500 text-xs">{errors.course_affiliation_.message}</span>}
                        </div>

                        <div className="w-full flex flex-col gap-2">
                            <label className="font-[400] text-xs text-gray-600">Select City <span className="text-red-400">*</span></label>
                            <select
                                disabled
                                name="city_of_ITI"
                                {...register("city_of_ITI", { required: "District is required" })}
                                className="border rounded-md px-3 py-2 text-sm w-full outline-none disabled:bg-gray-50 "
                            >
                                <option value="">Select City</option>
                                {
                                    haryanaCities?.map((city, i) => {
                                        return <option key={city + i} value={city}>{city}</option>
                                    })
                                }

                            </select>
                            {errors.city_of_ITI && <span className="text-red-500 text-xs">{errors.city_of_ITI.message}</span>}
                        </div>

                        <div className="w-full flex flex-col gap-2">
                            <label className="font-[400] text-xs text-gray-600">Select District <span className="text-red-400">*</span></label>
                            <select
                                disabled
                                name="district_of_ITI"
                                {...register("district_of_ITI", { required: "District is required" })}
                                className="border rounded-md px-3 py-2 text-sm w-full outline-none disabled:bg-gray-50 "
                            >
                                <option value="">Select District</option>
                                {
                                    haryanaDistricts?.map((district, i) => {
                                        return <option key={district} value={district}>{district}</option>
                                    })
                                }

                            </select>
                            {errors.district_of_ITI && <span className="text-red-500 text-xs">{errors.district_of_ITI.message}</span>}
                        </div>

                        <div className="w-full flex flex-col gap-2">
                            <label className="font-[400] text-xs text-gray-600">Select ITI <span className="text-red-400">*</span></label>
                            <select
                                disabled
                                {...register("name_of_your_ITI", { required: "ITI name is required" })}
                                className="border rounded-md px-3 py-2 text-sm w-full outline-none disabled:bg-gray-50 "
                            >
                                <option value="">Select ITI</option>
                                {
                                    itiNameList?.map(iti => {
                                        return <option key={iti} value={iti}>{iti}</option>
                                    })
                                }

                            </select>
                            {errors.itiName && <span className="text-red-500 text-xs">{errors.itiName.message}</span>}
                        </div>


                        <div className="w-full flex flex-col gap-2">
                            <label className="font-[400] text-xs text-gray-600">Select Batch of Trade <span className="text-red-400">*</span></label>
                            <select
                                disabled
                                {...register("batch_of_trade", { required: "Batch is required" })}
                                className="border rounded-md px-3 py-2 text-sm w-full outline-none disabled:bg-gray-50 "
                            >
                                <option value="">Select Batch</option>
                                <option value="2020-2022">2020 - 2022</option>
                                <option value="2021-2022">2021 - 2022</option>
                            </select>
                            {errors.batch_of_trade && <span className="text-red-500 text-xs">{errors.batch_of_trade.message}</span>}
                        </div>


                        <div className="w-full flex flex-col gap-2">
                            <label className="font-[400] text-xs text-gray-600">Select Trade <span className="text-red-400">*</span></label>
                            <select
                                disabled
                                {...register("name_of_trade", { required: "Trade is required" })}
                                className="border rounded-md px-3 py-2 text-sm w-full outline-none disabled:bg-gray-50 ">
                                <option value="">Select Trade</option>
                                {
                                    tradeList.map(trade => {
                                        return <option key={trade} value={trade}>{trade}</option>
                                    })
                                }
                            </select>
                            {errors.name_of_trade && <span className="text-red-500 text-xs">{errors.name_of_trade.message}</span>}
                        </div>

                        <div className="w-full flex flex-col gap-2">
                            <label className="font-[400] text-xs text-gray-600">Select Industry <span className="text-red-400">*</span></label>
                            <select
                                disabled
                                {...register("name_of_industry_partner", { required: "Industry is required" })}
                                className="border rounded-md px-3 py-2 text-sm w-full outline-none disabled:bg-gray-50 "
                            >
                                <option value="">Select Industry</option>
                                {
                                    industryList?.map((ind => {
                                        return (
                                            <option key={ind} value={ind}>{ind}</option>
                                        )
                                    }))
                                }
                            </select>
                            {errors.name_of_industry_partner && <span className="text-red-500 text-xs">{errors.name_of_industry_partner.message}</span>}
                        </div>

                        <div className="w-full flex flex-col gap-2">
                            <label className="font-[400] text-xs text-gray-600">Upload Image <span className="text-red-400">*</span></label>
                            <input
                                type="file"
                                {...register("image_url", { required: "Image is required!" })}
                                accept="image_url/*"
                                className="border rounded-md px-3 py-1.5 text-sm w-full outline-none "
                                // onChange={(e) => setImageFile(e.target.files[0])} // Store the selected image
                                required
                            />
                            {errors.image_url && <span className="text-red-500 text-xs">image_url is required</span>}
                        </div>

                        <div className="w-full flex flex-col gap-2">
                            <label className="font-[400] text-xs text-gray-600">Are these information are currect ? <span className="text-red-400">*</span></label>
                            <div className="flex mt-1.5 text-gray-500 gap-2 custom-radio">
                                <label className="flex items-center gap-1">
                                    <input
                                        readOnly
                                        // disabled
                                        type="radio"
                                        value='Yes'
                                        {...register("isInformationCurrect", { required: "Are these information are currect ?" })}
                                    />
                                    Yes
                                </label>
                                <label className="flex items-center gap-1">
                                    <input
                                        readOnly
                                        // disabled
                                        type="radio"
                                        value='No'
                                        {...register("isInformationCurrect", { required: "Are these information are currect ?" })}
                                    />
                                    No
                                </label>
                            </div>
                            {errors.isInformationCurrect && <span className="text-red-500 text-xs">{errors.isInformationCurrect.message}</span>}
                        </div>

                    </div>
                    <div className=" w-full mt-6 flex justify-center items-center space-x-2  ">

                        <Button
                            className='w-auto px-[10px] font-normal'
                            title='Record Attendance'
                            type="submit"
                        />
                    </div>
                </form>
            </div>
            <Modal isOpen={openModal}
                title="Your Current Location"
                onClose={() => setOpenModal(false)}>

                <CurrentLocation
                    handleSave={recordTraineeAttendance}
                    data={details} />

            </Modal>
        </div>
    );
};

export default TraineeDetails;